<template>
  <b-container fluid>
    <iq-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('external_research.research_report_submission') }}</h4>
      </template>
      <template v-slot:body>
        <b-row>
            <b-col lg="6" md="6" sm="12" xs="12">
                <b-form-group
                class="row"
                label-cols-sm="3"
                :label="$t('research_manage.thematic_area')"
                label-for="thematic_area_id"
                >
                <b-form-select
                    plain
                    v-model="search.thematic_area_id"
                    :options="thematicAreaList"
                    id="thematic_area_id"
                    >
                    <template v-slot:first>
                        <b-form-select-option :value="0">{{ $t('globalTrans.select') }}</b-form-select-option>
                    </template>
                </b-form-select>
                </b-form-group>
            </b-col>
            <b-col lg="6" md="6" sm="12" xs="12">
                <b-form-group
                class="row"
                label-cols-sm="3"
                :label="$t('research_manage.project_id')"
                label-for="project_status"
                >
                <b-form-input
                    plain
                    v-model="search.project_id"
                    id="project_status"
                    >
                </b-form-input>
                </b-form-group>
            </b-col>
        </b-row>
        <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
      </template>
    </iq-card>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('external_research.research_report_submission') + ' ' + $t('globalTrans.list') }}</h4>
          </template>
          <template v-slot:body>
            <b-overlay :show="loadingState">
              <b-row>
                <b-col md="12" class="table-responsive">
                  <b-table bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                    <template v-slot:cell(index)="data">
                      {{ $n(data.index + pagination.slOffset) }}
                    </template>
                    <template v-slot:cell(is_report_submit)="data">
                      <span class="badge badge-warning" v-if="data.item.is_report_submit === 0">{{ $t('globalTrans.not_submitted') }}</span>
                      <span class="badge badge-danger" v-else-if="data.item.is_report_submit === 1">{{ $t('globalTrans.pending') }}</span>
                      <span class="badge badge-success" v-else-if="data.item.is_report_submit === 2">{{ $t('globalTrans.approved') }}</span>
                      <span class="badge badge-danger" v-else>{{$t('globalTrans.rejected')}}</span>
                    </template>
                    <template v-slot:cell(action)="data">
                        <!-- <b-button v-b-modal.modal-5 variant=" iq-bg-success" v-if="data.item.is_report_submit !== 1" size="sm" class="mr-1" title="Edit" @click="viewApplication(data.item)"><i class="ri-edit-line"></i></b-button> -->
                        <b-button v-if="data.item.is_report_submit === 0 || data.item.is_report_submit === 3" v-b-modal.modal-5 variant=" iq-bg-success" size="sm" class="mr-1" title="Edit" @click="viewApplication(data.item)"><i class="ri-edit-line"></i></b-button>
                        <b-button v-b-modal.modal-1 variant=" iq-bg-success mr-1 mb-1" size="sm" title="View" @click="details(data.item)">
                            <i class="ri-eye-line m-0"></i>
                        </b-button>
                    </template>
                  </b-table>
                  <b-pagination
                    v-model="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalRows"
                    @input="searchData"
                    />
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-modal id="modal-5" size="lg" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
        <FormV :id="viewItemId" :key="viewItemId"/>
    </b-modal>
    <b-modal id="modal-1" size="lg" :title="this.$t('certify_form.application_details')"  :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <Details :item="application" :key="viewItemId"/>
    </b-modal>
  </b-container>
</template>

<script>
import FormV from './Form.vue'
import RestApi, { agriResearchServiceBaseUrl } from '@/config/api_config'
import { getApprovedApplicationList, getProposalData } from '../../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import { core } from '@/config/pluginInit'
import moment from 'moment'
import Details from './Details'
export default {
    components: {
        FormV, Details
    },
    mixins: [ModalBaseMasterList],
    data () {
        return {
            search: {
                thematic_area_id: 0,
                project_id: ''
            },
            application: {},
            viewItemId: 0,
            info: {
                user_id: this.$store.state.Auth.authUser.id
            }
        }
    },
    computed: {
        thematicAreaList: function () {
            return this.$store.state.ExternalAgriResearch.agriResearchCommonObj.thematicAreaList.filter(item => item.status === 1)
        },
        formTitle () {
            return this.$t('certify_form.application_details')
        },
        columns () {
            const labels = [
                { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
                { label: this.$t('external_research.project_id'), class: 'text-left' },
                { label: this.$t('external_research.project_title'), class: 'text-left' },
                { label: this.$t('external_research.thematic_area'), class: 'text-left' },
                { label: this.$t('globalTrans.status'), class: 'text-center' },
                { label: this.$t('globalTrans.action'), class: 'text-center' }
            ]

            let keys = []

            if (this.$i18n.locale === 'bn') {
                keys = [
                    { key: 'index' },
                    { key: 'proposal_auto_id' },
                    { key: 'project_title_bn' },
                    { key: 'thematic_area_bn' },
                    { key: 'is_report_submit' },
                    { key: 'action' }
                ]
            } else {
                keys = [
                    { key: 'index' },
                    { key: 'proposal_auto_id' },
                    { key: 'project_title' },
                    { key: 'thematic_area' },
                    { key: 'is_report_submit' },
                    { key: 'action' }
                ]
            }
            return labels.map((item, index) => {
                return Object.assign(item, keys[index])
            })
        }
    },
    mounted () {
        core.index()
        this.loadData()
    },
    methods: {
      async searchData () {
          this.loadData()
      },
        viewApplication (item) {
            this.viewItemId = item.id
        },
        details (item) {
            this.application = item
            this.viewItemId = item.id
        },
        getDate (date) {
            return moment(date).format('L')
        },
        loadData () {
            const params = Object.assign({}, this.info, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
            this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
            RestApi.getData(agriResearchServiceBaseUrl, getApprovedApplicationList, getProposalData, params).then(response => {
                if (response.success) {
                    this.$store.dispatch('setList', this.getRelationalData(response.data.data))
                    this.paginationData(response.data)
                }
                this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
            })
        },
        getRelationalData (data) {
            const FilterData = data.filter(newItm => newItm.activity_status === newItm.activities_count)
            const listData = FilterData.map(item => {
                // const thematic = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.thematicAreaList.find(newItem => newItem.value === parseInt(item.circular_main_id))
                const thematic = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.thematicAreaList.find(newItem => newItem.value === parseInt(item.invitation.thematic_area_id))
                const newData = {
                    thematic_area: thematic !== undefined ? thematic.text_en : '',
                    thematic_area_bn: thematic !== undefined ? thematic.text_bn : ''
                }
                return Object.assign({}, item, newData)
            })
            return listData
        }
    }
}
</script>
