<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <b-overlay :show="unitLoad">
          <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
            <b-form  @submit.prevent="handleSubmit(saveData)" @reset.prevent="reset">
                <div class="row">
                  <b-col md="6">
                      <ValidationProvider name="Project Id" vid="project_id" rules="required">
                        <b-form-group class="row" label-cols-sm="12" label-for="project_id" slot-scope="{ valid, errors }">
                            <template v-slot:label>
                            {{$t('external_research.project_id')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                                disabled
                                plain
                                id="project_id"
                                v-model="proposal.project_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                            >
                            </b-form-input>
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                            </div>
                        </b-form-group>
                      </ValidationProvider>
                  </b-col>
                  <b-col md="6">
                      <ValidationProvider name="Thematic Area" vid="thematic_area" rules="required">
                        <b-form-group class="row" label-cols-sm="12" label-for="thematic_area" slot-scope="{ valid, errors }">
                            <template v-slot:label>
                            {{$t('external_research.thematic_area')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                                disabled
                                plain
                                id="thematic_area"
                                v-model="proposal.thematic_area"
                                :state="errors[0] ? false : (valid ? true : null)"
                            >
                            </b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                        </b-form-group>
                      </ValidationProvider>
                  </b-col>
                  <b-col md="6">
                      <b-form-group class="row"
                        label-cols-sm="12"
                        label-for="sector_name"
                      >
                        <template v-slot:label>
                          {{$t('external_research.sector_name')}} <span class="text-danger">*</span>
                        </template>
                        <b-table-simple class="mb-0" bordered small hover>
                          <tbody>
                            <b-tr>
                                <b-td>{{ proposal.sector_name }}</b-td>
                            </b-tr>
                          </tbody>
                        </b-table-simple>
                      </b-form-group>
                  </b-col>
                  <b-col md="6">
                      <b-form-group class="row"
                        label-cols-sm="12"
                        label-for="sector_name"
                      >
                        <template v-slot:label>
                          {{$t('external_research.sub_sector_name')}} <span class="text-danger">*</span>
                        </template>
                        <b-table-simple bordered small hover class="mb-0">
                          <tbody>
                            <b-tr>
                                <b-td>{{ proposal.sub_sector_name }}</b-td>
                            </b-tr>
                          </tbody>
                        </b-table-simple>
                      </b-form-group>
                  </b-col>
                  <b-col md="6">
                      <ValidationProvider name="Project title" vid="project_title" rules="required">
                        <b-form-group class="row" label-cols-sm="12" label-for="project_title" slot-scope="{ valid, errors }">
                            <template v-slot:label>
                            {{$t('external_research.project_title')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                                disabled
                                plain
                                id="project_title"
                                v-model="proposal.project_title"
                                :state="errors[0] ? false : (valid ? true : null)"
                            >
                            </b-form-input>
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                            </div>
                        </b-form-group>
                      </ValidationProvider>
                  </b-col>
                  <b-col md="6">
                      <ValidationProvider name="Completion Report" vid="completion_report">
                      <b-form-group class="row" label-cols-sm="12" label-for="completion_report" slot-scope="{ valid, errors }">
                          <template v-slot:label>
                          {{ $t('external_research.completion_report') }}
                          <!-- <v-slot v-if="main_form.completion_report !== null">
                              <a :href="baseUrl + 'download-attachment?file=uploads/general-information/attachment/original/' + main_form.completion_report" title="Evaluation Attachment" class="mr-3"><i class="ri-download-cloud-fill"></i></a>
                          </v-slot> -->
                          </template>
                          <b-form-file
                          id="completion_report"
                          @change="onPhotoChange"
                          v-model="proposal.attachment"
                          :state="errors[0] ? false : (valid ? true : null)"
                          >
                          </b-form-file>
                          <div class="invalid-feedback">
                          {{ errors[0] }}
                          </div>
                      </b-form-group>
                      </ValidationProvider>
                  </b-col>
                  <b-col md="12" lg="12" sm="6">
                    <ValidationProvider name="Remarks" vid="remarks_en" >
                        <b-form-group
                            class="row"
                            label-cols-sm="12"
                            label-for="remarks_en"
                            >
                              <template v-slot:label>
                                  {{$t('globalTrans.remarks')}}
                              </template>
                              <b-form-textarea
                                  id="remarks_en"
                                  v-model="proposal.remarks_en"
                                  class="mb-3"
                                  name="remarks_en"
                              >
                              </b-form-textarea>
                        </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <!-- <b-col md="6" v-if ="is_report_submit => 1">
                    <ValidationProvider name="Remarks(Bn)" vid="remarks_bn" >
                        <b-form-group
                            class="row"
                            label-cols-sm="12"
                            label-for="remarks_bn"
                            >
                                <template v-slot:label>
                                    {{$t('certify_form.remark_bn')}}
                                </template>
                                <b-form-textarea
                                    id="remarks_bn"
                                    v-model="proposal.remarks_bn"
                                    class="mb-3"
                                    name="remarks_bn"
                                >
                                </b-form-textarea>
                        </b-form-group>
                    </ValidationProvider>
                  </b-col> -->
                </div>
                <div class="row">
                  <b-col class="text-right">
                     <b-button type="submit" variant="primary" class="mr-1">{{ saveBtnName }}</b-button>
                      &nbsp;
                      <b-button variant="danger" @click="$bvModal.hide('modal-5')">{{ $t('globalTrans.cancel') }}</b-button>
                  </b-col>
                </div>
            </b-form>
          </ValidationObserver>
        </b-overlay>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '../../../../../../config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { agriResearchServiceBaseUrl } from '@/config/api_config'
import { approvedApplicationReportStore, getProposalData } from '../../../api/routes'
import researchTestingModal from '@/mixins/research-testing-modal'
export default {
  name: 'FormLayout',
  props: ['id'],
  mixins: [researchTestingModal],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  data () {
    return {
      defaultValue: '',
      saveBtnName: this.$t('globalTrans.submit'),
      proposal: {
        proposal_id: 0,
        thematic_area: '',
        project_id: '',
        sector_name: '',
        sub_sector_name: '',
        project_title: '',
        attachment: [],
        file: '',
        remarks_en: '',
        remarks_bn: ''
      },
      unitLoad: false
    }
  },
  computed: {
  },
  created () {
    if (this.id) {
        this.getProjectData()
    }
  },
  mounted () {
    core.index()
  },
  methods: {
    // getProjectData () {
    //     const tmpData = this.$store.state.list.find(item => item.id === parseInt(this.$route.query.id))
    //     return JSON.parse(JSON.stringify(tmpData))
    // },
    getProjectData () {
      this.unitLoad = true
      RestApi.postData(agriResearchServiceBaseUrl, getProposalData, { proposal_id: this.id })
      .then(response => {
        if (response.success) {
          this.getRelationalData(response.data)
          // this.$store.dispatch('mutateCommonProperties', { loading: false })
        }
        this.unitLoad = false
      })
    },
    getRelationalData (data) {
      this.proposal.proposal_id = data.id
      this.proposal.project_id = data.proposal_auto_id
      this.proposal.project_title = this.$i18n.locale === 'en' ? data.project_title : data.project_title_bn
      if (data.report_submission) {
        this.proposal.file = data.report_submission.attachment
        this.proposal.remarks_en = data.report_submission.remarks_en
        this.proposal.remarks_bn = data.report_submission.remarks_bn
      }
      this.proposal.thematic_area = this.getThematicAreaName(data.invitation.thematic_area_id)
      this.proposal.invitation = data.invitation

      // const sector = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.sectorList.find(newItem => newItem.value === parseInt(data.invitation.id))
      // const circular = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.circularList.find(newItem => newItem.value === parseInt(data.circular_main_id))
      // const thematic = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.thematicAreaList.find(newItem => newItem.value === parseInt(circular.thematic_area_id))
      // if (this.$i18n.locale === 'bn') {
      //   this.proposal.thematic_area = thematic.text_bn
      //   this.proposal.sector_name = sector.text_bn
      // } else {
      //   this.proposal.sector_name = sector.text_en
      //   this.proposal.thematic_area = thematic.text_en
      // }

      // data.invitation.proposal_invitation_details.map(item => {
        if (data.invitation.proposal_invitation_details) {
          const sector = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.sectorList.find(newItem => newItem.value === parseInt(data.invitation.proposal_invitation_details[0].sector_id))
          if (this.$i18n.locale === 'bn') {
            this.proposal.sector_name = sector.text_bn
          } else {
            this.proposal.sector_name = sector.text_en
          }
        } else {
          this.proposal.sector_name = ''
        }
      // })
        if (data.invitation.proposal_invitation_details) {
          const subSector = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.subSectorList.find(newItem => newItem.value === parseInt(data.invitation.proposal_invitation_details[0].sub_sector_id))
          if (this.$i18n.locale === 'bn') {
            this.proposal.sub_sector_name = subSector.text_bn
          } else {
            this.proposal.sub_sector_name = subSector.text_en
          }
        } else {
          this.proposal.sub_sector_name = ''
        }
    },
    getThematicAreaName (Id) {
      const Obj = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.thematicAreaList.find(newItem => newItem.value === parseInt(Id))
      if (this.$i18n.locale === 'en') {
        return Obj.text_en
      } else {
        return Obj.text_bn
      }
    },
    onPhotoChange (event) {
      const input = event.target
      const format = ['pdf', 'doc', 'docx', 'xls', 'xlsx']
      if (input.files && input.files[0]) {
          const ext = input.files[0].name.slice((input.files[0].name.lastIndexOf('.') - 1 >>> 0) + 2)
          const isSupport = format.find(el => el === ext)
          if (typeof isSupport !== 'undefined') {
            const reader = new FileReader()
            reader.onload = (e) => {
                this.proposal.file = e.target.result
            }
            reader.readAsDataURL(input.files[0])
          } else {
            this.$toast.error({
                title: 'Invalid File!',
                message: 'Only pdf, doc, docx, xls and xlsx files are allowed',
                color: '#D6E09B'
            })
            this.proposal.attachment = []
            this.proposal.file = ''
          }
      } else {
          this.proposal.file = ''
      }
    },
    async saveData () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadingState = { loading: false, listReload: false }
      result = await RestApi.postData(agriResearchServiceBaseUrl, approvedApplicationReportStore, this.proposal)
      loadingState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadingState)
      if (result.success) {
        this.$store.dispatch('AgriResearch/mutateCommonObj', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.id ? 'Data updated successfully' : 'Data save successfully',
          color: '#D6E09B'
        })
        this.$bvModal.hide('modal-5')
      } else {
        this.$refs.form.setErrors(result.errors)
        this.$toast.error({
          title: 'Error',
          message: 'Operation failed! Please, try again.'
        })
      }
    },
    getSeedList (typeId = null) {
      const seedNameList = this.$store.state.AgriResearch.commonObj.seedNameList
      if (typeId) {
        const names = seedNameList.filter(seed => seed.seed_type_id === typeId && !seed.status)
        return names.map(item => {
            return { value: item.value, text: this.$i18n.locale === 'en' ? item.text_en : item.text_bn }
        })
      }
      return seedNameList
    }
  }
}
</script>
