<template>
  <b-container fluid class="text-dark">
    <b-row >
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-overlay :show="unitLoad">
              <b-table-simple bordered small hover class="request_dtls_wrapper">
                <tbody>
                  <b-tr>
                      <b-th>{{$t('external_research.project_id')}}</b-th>
                      <b-td>{{ proposal.project_id }} </b-td>
                      <b-th>{{$t('external_research.thematic_area')}}</b-th>
                      <b-td>{{ proposal.thematic_area }}</b-td>
                  </b-tr>
                  <b-tr>
                      <b-th>{{$t('external_research.sector_name')}}</b-th>
                      <b-td>{{ proposal.sector_name }} </b-td>
                      <b-th>{{$t('external_research.sub_sector_name')}}</b-th>
                      <b-td>{{ proposal.sub_sector_name }} </b-td>
                  </b-tr>
                  <b-tr>
                      <b-th>{{$t('external_research.project_title')}}</b-th>
                      <b-td>{{ $i18n.locale === 'en' ? proposal.project_title : proposal.project_title_bn }}</b-td>
                       <b-th>{{ $t('external_research.completion_report') }}</b-th>
                      <b-td>
                        <a target="_blank" v-if="proposal.file" :href="url+'storage/report-submission/'+ proposal.file">
                          <i class="fa fa-cloud" aria-hidden="true"></i>
                          {{ $t('globalTrans.attachment') }}
                        </a>
                      </b-td>
                  </b-tr>
                  <b-tr>
                      <b-th>{{$t('certify_form.remarks')}}</b-th>
                      <b-td colspan="3">{{ proposal.remarks }}</b-td>
                  </b-tr>
                </tbody>
              </b-table-simple>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '../../../../../../config/pluginInit'
import RestApi, { agriResearchServiceBaseUrl } from '@/config/api_config'
import { getProposalData } from '../../../api/routes'
import researchTestingModal from '@/mixins/research-testing-modal'
export default {
  name: 'FormLayout',
  props: ['item'],
  mixins: [researchTestingModal],
  components: {
  },
  data () {
    return {
      url: agriResearchServiceBaseUrl,
      defaultValue: '',
      saveBtnName: this.$t('globalTrans.save'),
      proposal: {
        proposal_id: 0,
        thematic_area: '',
        project_id: '',
        sector_name: '',
        sub_sector_name: '',
        project_title: '',
        attachment: [],
        file: '',
        remarks_en: '',
        remarks_bn: ''
      },
      unitLoad: false,
      proposal_id: 0
    }
  },
  computed: {
    loadingState () {
        if (this.listReload) {
        return true
        } else if (this.loading) {
            return true
        } else {
        return false
        }
    },
    serviceList: function () {
      const types = this.$store.state.AgriResearch.commonObj.serviceList.filter(el => el.status === 0)
      return types.map(item => {
            return { value: item.value, text: this.$i18n.locale === 'en' ? item.text_en : item.text_bn }
          })
    }
  },
  created () {
    if (this.item) {
        this.getData(this.item)
    }
  },
  mounted () {
    core.index()
  },
  methods: {
    getData () {
      this.unitLoad = true
      RestApi.postData(agriResearchServiceBaseUrl, getProposalData, { proposal_id: this.item.id })
      .then(response => {
        if (response.success) {
          this.getRelationalData(response.data)
        }
        this.unitLoad = false
      })
    },
    getRelationalData (data) {
      this.proposal.proposal_id = this.item.id
      this.proposal.project_id = this.item.proposal_auto_id
      this.proposal.project_title = this.item.project_title
      if (data.report_submission) {
        this.proposal.file = data.report_submission.attachment
        this.proposal.remarks = this.$i18n.locale === 'en' ? data.report_submission.remarks_en : data.report_submission.remarks_bn
      } else {
        this.proposal.file = ''
        this.proposal.remarks = ''
      }
      // const circular = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.circularList.find(newItem => newItem.value === parseInt(data.circular_main_id))
      const thematic = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.thematicAreaList.find(newItem => newItem.value === parseInt(data.invitation.thematic_area_id))
      if (this.$i18n.locale === 'bn') {
        this.proposal.thematic_area = thematic.text_bn
      } else {
        this.proposal.thematic_area = thematic.text_en
      }
      data.invitation.proposal_invitation_details.map(item => {
        const sector = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.sectorList.find(newItem => newItem.value === parseInt(item.sector_id))
        const subSector = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.subSectorList.find(newItem => newItem.value === parseInt(item.sub_sector_id))
        if (this.$i18n.locale === 'bn') {
          this.proposal.sector_name = sector.text_bn
          this.proposal.sub_sector_name = subSector.text_bn
        } else {
          this.proposal.sector_name = sector.text_en
          this.proposal.sub_sector_name = subSector.text_en
        }
      })
    }
  }
}
</script>
